import cx from 'clsx';
import { forwardRef } from 'react';

import { Box } from '@/common/components/Display/Box';
import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { getIconSize } from '@/common/utils/IconFunctions';

import classes from './Icon.module.css';
import { IconProps } from './IconProps';

/**
 * @deprecated just use 'currentColor'
 */
export const getDefaultColor = () => 'currentColor';

export const IconSvg = forwardRef<HTMLSpanElement, IconProps>(
  (
    {
      display = 'inline-flex',
      size,
      component = 'span',
      td = 'none',
      ta = 'center',
      onClick,
      children,
      className,
      flipped,
      svgProps,
      color,
      __vars,
      ...rest
    },
    ref
  ) => {
    const widthHeight = !!size ? getIconSize(size) : undefined;

    const handleClick = useEvent<IconProps['onClick']>((e) => {
      if (!rest.disabled) onClick?.(e);
    });

    const isClickable = !!onClick;
    if (rest.href && (rest as any).component) {
      (rest as any).component = 'a';
    }

    return (
      <Box
        ref={ref as any}
        display={display}
        td={td}
        component={component as any}
        ta={ta}
        data-icon-root
        className={cx(classes.root, 'icon-root', className)}
        w={widthHeight}
        h={widthHeight}
        __vars={{
          '--icon-color': color || 'currentColor',
          ...__vars
        }}
        data-flipped={flipped ? 'true' : 'false'}
        data-disabled={rest.disabled ? 'true' : 'false'}
        aria-disabled={rest.disabled ? 'true' : 'false'}
        data-clickable={isClickable ? 'true' : 'false'}
        role={isClickable ? 'button' : undefined}
        onClick={isClickable ? handleClick : undefined}
        {...(rest as any)}
      >
        <svg
          className={classes.svg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          {...(svgProps || {})}
        >
          {children}
        </svg>
      </Box>
    );
  }
);
