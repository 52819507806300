import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { getClampedSize } from '@komo-tech/core/utils/size';

import { isLocalhost } from '@/common/utils/NextFunctions';

const MaxImageDimension = 1920;

/**
 * Returns if the URL is a Komo CDN image.
 * @param src The URL of the image.
 * @returns True if a Komo CDN image, else false.
 */
export const isKomoCdnImage = (src: string) => {
  if (!src) return false;
  if (src.indexOf('komocdn.com') > -1) {
    return true;
  }

  if (isLocalhost() && src.indexOf('localhost:3000') > -1) {
    return true;
  }

  return false;
};

/**
 * Returns if the URL is the Komo legacy image service.
 * @param src The URL of the image.
 * @returns True if an Komo legacy image, else false.
 */
export const isLegacyKomoImage = (src: string) =>
  (src ?? '').indexOf('quizjam-images.azureedge.net') > -1 ||
  (src ?? '').indexOf('quizjam-images-staging.azureedge.net') > -1;

/**
 * Returns if the URL is an unsplash image.
 * Examples:
 * - images.unsplash.com
 *
 * @param src The URL of the image.
 * @returns True if an unsplash image, else false.
 */
export const isUnsplashImage = (src: string) =>
  (src ?? '').indexOf('.unsplash.com') > -1;

/**
 * Returns if the URL is an pexels image.
 * Examples:
 * - images.pexels.com
 *
 * @param src The URL of the image.
 * @returns True if an pexels image, else false.
 */
export const isPexelsImage = (src: string) =>
  (src ?? '').indexOf('.pexels.com') > -1;

/**
 * Returns if the URL is an unsplash image.
 * Examples:
 * - media.giphy.com
 * - media0.giphy.com
 * - media1.giphy.com
 * - media2.giphy.com
 * - media3.giphy.com
 * - media4.giphy.com
 * - media20.giphy.com
 *
 * @param src The URL of the image.
 * @returns True if a giphy image, else false.
 */
export const isGiphyImage = (src: string) =>
  (src ?? '').indexOf('.giphy.com') > -1;

interface GetImageClampedSizesOptions {
  size: Partial<HeightWidth>;
  max?: number;
}

const isOverZero = (value: number | undefined) => value > 0;

export const getClampedImageSize = ({
  size,
  max = MaxImageDimension
}: GetImageClampedSizesOptions): Partial<HeightWidth> => {
  if (!isOverZero(size.width) && !isOverZero(size.height)) {
    return { height: undefined, width: undefined };
  }
  if (isOverZero(size.width) && isOverZero(size.height)) {
    return getClampedSize({
      size: size as HeightWidth,
      max,
      roundingDecimals: 0
    });
  }
  return isOverZero(size.width)
    ? { width: size.width > max ? max : size.width, height: undefined }
    : { width: undefined, height: size.height > max ? max : size.height };
};

interface NextImageLoaderOptions {
  src: string;
  width?: number;
  height?: number;
}

type GetClampedImageUrlOptions = NextImageLoaderOptions & {
  keys: { width: string; height: string };
  max?: number;
};

export const getClampedImageUrl = ({
  src,
  width,
  height,
  max = MaxImageDimension,
  keys
}: GetClampedImageUrlOptions) => {
  if (!isOverZero(width) && !isOverZero(height)) {
    return src;
  }
  const newSize = getClampedImageSize({ size: { width, height }, max });
  const url = new URL(src);
  url.searchParams.delete(keys.width);
  if (isOverZero(newSize.width)) {
    url.searchParams.set(keys.width, newSize.width.toString());
  }
  url.searchParams.delete(keys.height);
  if (height) {
    url.searchParams.set(keys.height, newSize.height.toString());
  }
  return url.toString();
};

export const ImageLoaderParamKeys = {
  Komo: { width: 'width', height: 'height' },
  Giphy: { width: 'w', height: 'h' },
  Unsplash: { width: 'w', height: 'h' },
  Pexels: { width: 'w', height: 'h' }
};

export const ImageLoader = ({ src, width, height }: NextImageLoaderOptions) => {
  if (isKomoCdnImage(src) || isLegacyKomoImage(src)) {
    return getClampedImageUrl({
      src,
      width,
      height,
      keys: ImageLoaderParamKeys.Komo
    });
  }
  if (isUnsplashImage(src)) {
    return getClampedImageUrl({
      src,
      width,
      height,
      keys: ImageLoaderParamKeys.Unsplash
    });
  }
  if (isPexelsImage(src)) {
    return getClampedImageUrl({
      src,
      width,
      height,
      keys: ImageLoaderParamKeys.Pexels
    });
  }
  if (isGiphyImage(src)) {
    return getClampedImageUrl({
      src,
      width,
      height,
      keys: ImageLoaderParamKeys.Giphy
    });
  }
  return src;
};
